/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}



/**
 * Basic styling
 */
body {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  color: $text-color;
  background-color: $background-color;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
     -moz-font-feature-settings: "kern" 1;
       -o-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: normal;
  word-wrap: break-word;
  word-break: normal;
}



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: $spacing-unit / 2;
}



/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
}



/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  font-size: $small-font-size;
}



/**
 * Lists
 */
ul, ol {
  margin-left: $spacing-unit;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
	font-family: $heading-font-family;
  font-weight: $base-font-weight;
}



/**
 * Links
 */
a {
  color: $link-color;
  text-decoration: none;

  &:visited {
    color: darken($link-color, 15%);
  }

  &:hover {
    color: $text-color;
  }
}

%a-secondary {
  color: $secondary-text-color;
  text-decoration: none;

  &:hover {
    color: $text-color;
  }

  &:visited {
    color: $secondary-text-color;
  }
}

%a-tertiary {
  color: $tertiary-text-color;
  text-decoration: none;

  &:hover {
    color: $text-color;
  }

  &:visited {
    color: $tertiary-text-color;
  }
}



/**
 * Blockquotes
 */
blockquote {
  padding: $spacing-unit $spacing-unit / 2;
  font-size: 22px;
  color: $grey-color;
  letter-spacing: -1px;
  text-align: center;

  p {
    quotes: "“" "”" "‘" "’";
    display: inline;
  }
  p:before {
    content: open-quote;
  }
  p:after {
    content: close-quote;
  }

  > :last-child {
    margin-bottom: 0;
  }
}



/**
 * Code formatting
 */
pre,
code {
  font-size: 15px;
  border: 1px solid darken($code-background-color, 3%);
  border-radius: 3px;
  background-color: $code-background-color;
  word-wrap: normal;
  word-break: normal;
}

code {
  padding: 1px 5px;
  font-family: $code-font-family;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}



/**
 * Wrapper
 */
.wrapper {
  max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
  max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
  margin-right: auto;
  margin-left: auto;
  padding-right: $spacing-unit;
  padding-left: $spacing-unit;
  @extend %clearfix;

  @include media-query($on-laptop) {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
    max-width:         calc(#{$content-width} - (#{$spacing-unit}));
    padding-right: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
  }
}



/**
 * Clearfix
 */
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}



/**
 * Icons
 */
.icon > svg {
  display: inline-block;
  vertical-align: middle;

  path {
    fill: $grey-color;
  }
}



/**
 * Tables
 */
table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: $spacing-unit / 2;
}

td, th {
  border: 1px solid $grey-color;
  padding: 8px;
}

thead {
  background-color: lighten($grey-color, 10%);
}

tr:nth-child(even) {
  background-color: lighten($grey-color, 10%);
}



/**
 * Horizontal Rule
 */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $grey-color-light;
  margin: $spacing-unit 0;
  padding: 0;
}
