@charset "utf-8";

// Our variables
$base-font-family:          "Gill Sans","Gill Sans MT",Calibri,sans-serif;
$heading-font-family:       "Gill Sans","Gill Sans MT",Calibri,sans-serif;
$code-font-family:          monospace;
$base-font-size:            16px;
$base-font-weight:          normal;
$small-font-size:           $base-font-size * 0.875;
$base-line-height:          1.5;

$spacing-unit:              30px;

$text-color:                #333333;
$secondary-text-color:      #5D5966;
$tertiary-text-color:  	    #908C99;
$background-color:          #F5EFEA;
$code-background-color:     #F0EAF7;

$link-color:                #5C7BB7;

$grey-color:                #828282;
$grey-color-light:          #C7C6CC;


// Width of the content     area
$content-width:             1100px;

$on-palm:                   600px;
$on-laptop:                 800px;

@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@import "base";


html, body {
  background: $background-color;
}

// Sticky footer
body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
body > main {
  flex: 1;
}

main {
  margin: 0 auto;
  padding: 40px 20px 60px;
  font-size: 24px;
  max-width: 568px;

  @include media-query($on-palm) {
		padding: 20px 20px 60px;
    font-size: 20px;
  }
}

footer {
  text-align: center;
  font-size: 14px;
  color: $tertiary-text-color;
  a {
    @extend %a-tertiary;
  }
}
